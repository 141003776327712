import { Alert, AlertIcon, Link, AlertDescription, IconButton, Icon } from '@ecoinvent/ui';
import { BiX } from 'react-icons/bi';

import useCloseableAlertState from 'hooks/useCloseableAlertState';

const IPCCAlert = () => {
  const { isOpen, onClose } = useCloseableAlertState('ipccWarning');

  return (
    isOpen && (
      <Alert status="warning">
        <AlertIcon />
        <AlertDescription fontSize="sm">
          This method gives credit for carbon dioxide that is removed from air in biomass growth. Therefore, the scores should
          be handled with care, especially if they are negative, and they should never be used as a stand-alone score. We
          recommend using “IPCC 2021” whenever possible.{' '}
          <Link colorScheme="orange" href="https://support.ecoinvent.org/guidance-on-ipcc-methods">
            Learn more about our IPCC methods.
          </Link>
        </AlertDescription>
        <IconButton onClick={onClose} aria-label={'close'} icon={<Icon as={BiX} />} variant={'ghost'} colorScheme="orange" />
      </Alert>
    )
  );
};

export default IPCCAlert;
