import React from 'react';

import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@ecoinvent/ui';
import { LuSparkles } from 'react-icons/lu';

import DocumentationMarkdownRenderer from 'pages/Documentation/components/DocumentationMarkdownRenderer';

import { releaseMdString } from './releaseInfo';

const WhatsNew = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button size="sm" variant="link" color="white" rightIcon={<LuSparkles />} onClick={onOpen}>
        What's New
      </Button>
      <Modal size="4xl" onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={'3xl'}>Welcome to ecoQuery!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={5}>
              <DocumentationMarkdownRenderer components={{ a: (props) => <Link {...props} isExternal /> }}>
                {releaseMdString}
              </DocumentationMarkdownRenderer>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variant={'subtle'} size="sm" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default WhatsNew;
